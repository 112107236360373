/* loader.css */

.loader-container {
    display: flex;
    justify-content: start;
    align-items: center;
    height:30px;
    width:auto
}

.bouncing-dots {
    display: flex;
    justify-content: space-around;
    
}

.dot {
    width: 5px;
    height: 5px;
    margin-right: 5px;
    background-color: black;
    border-radius: 50%;
    animation: bounce 1.5s infinite;
}

.dot:nth-child(1) {
    animation-delay: 0s;
}

.dot:nth-child(2) {
    animation-delay: 0.3s;
}

.dot:nth-child(3) {
    animation-delay: 0.6s;
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
}
