@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-sans;
}

#tawkchat-container {
  z-index: 9999 !important;
  position: fixed !important;
  bottom: 0 !important;
  right: 0 !important;
  margin-bottom: 40px !important;
}
.carousel-root {
  display: flex;
  justify-content: center;
  align-items: center;
}
::-ms-reveal {
  display: none;
}
/* @keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
} */

/* @keyframes blink {
  from {
    border-right-color: transparent;
  }

  to {
    border-right-color: white;
  }
} */

.typewriter {
  border-right: 2px solid white;

  animation: typing 3s steps(40, end) forwards, blink 0.75s step-end infinite;
}
.backface-hidden {
  backface-visibility: hidden;
}

.transform-style-3d {
  transform-style: preserve-3d;
}

.rotate-y-180 {
  transform: rotateY(180deg);
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4 {
  font-weight: bold;
  margin-top: 35px;
}

.markdown-content ol {
  list-style-type: lower-alpha;
}
.markdown-content ul {
  list-style-type: disc;
  margin-top: 20px;
  margin-left: 15px;
}
.markdown-content p {
  margin-bottom: 15px;
  overflow: auto;
}

.markdown-content ul ul {
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 40px;
}

.markdown-content {
  padding: 10px;
  word-spacing: 2px;
  width: 100%;
}

.markdown-content li {
  margin: 15px 20px;
}

.markdown-content strong {
  font-weight: bold;
}
.markdown-content hr {
  margin-bottom: 20px;
  background-color: white;
  border: none;
}

/* Base styles */
.markdown-content table {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: small;
  overflow-x: auto;
}

.markdown-content th,
.markdown-content td {
  padding: 12px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #4c5052;
  min-width: 30px;
}

.dark .markdown-content th {
  background-color: #3c3547;
  color: #e0e0e0;
}

.markdown-content tr:nth-child(even) {
  background-color: #f2f0f0;
}

.dark .markdown-content tr:nth-child(even) {
  background-color: #1c142d;
}
.markdown-content th {
  font-weight: bolder;
  background-color: #d4d2d2;
  padding: 8px;
}

.katex-mathml {
  display: none;
  max-width: max-content;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.katex {
  max-width: 100%;
  overflow: auto;
}

.katex-display {
  max-width: max-content;
  word-wrap: break-word;
  white-space: pre-wrap;
}

/* Large screens optimization */
@media (min-width: 1024px) {
  .markdown-content table {
    font-size: small;
  }

  .markdown-content th,
  .markdown-content td {
    padding: 10px;
    min-width: 60px;
  }
}

/* Small screens optimization */
@media (max-width: 768px) {
  .markdown-content table {
    font-size: xx-small;
  }

  .markdown-content th,
  .markdown-content td {
    padding: 8px;
  }

  .markdown-content th {
    padding: 6px;
  }
}
.markdown-content a {
  color: rgb(14, 14, 250);
}

@keyframes sway {
  0%,
  100% {
    transform: rotate(3deg);
  }

  50% {
    transform: rotate(-3deg);
  }
}

.animate-sway {
  animation: sway 3s ease-in-out infinite;
}
@keyframes shine {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}

.height-calc {
  height: calc(80vh - 64px);
}
.custom-list {
  list-style-type: upper-roman;
}
.parallax-effect {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translateZ(0);
}

@media (max-width: 1024px) {
  /* Adjust for mobile and smaller screens */
  .parallax-effect {
    background-attachment: scroll;
    /* Disable parallax on smaller screens */
  }
}

.animate-fadeIn {
  animation: fadeIn 1s ease-in-out;
}

.animate-slideUp {
  animation: slideUp 0.8s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(40px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Flip Down */
@keyframes flip-down {
  0% {
    transform: rotateX(-90deg);
    opacity: 0;
  }

  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}

.animate-flip-down {
  animation: flip-down 0.5s ease-out forwards;
}

/* Flip Up */
@keyframes flip-up {
  0% {
    transform: rotateX(90deg);
    opacity: 0;
  }

  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}

.animate-flip-up {
  animation: flip-up 0.5s ease-out forwards;
}
